// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
import ApiService from "@/core/services/api.service";
import API from "../../api";

const state = {
  user_personal_info: JSON.parse(window.localStorage.getItem('user')),
  user_account_info: {
    username: "nick84",
    email: "nick.watson@loop.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  }
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    return new Promise(resolve => {
      ApiService.post(`${API.API_URL}/update-profile`, payload)
        .then(({ data }) => {
          var get_data = JSON.parse(window.localStorage.getItem('user'));
          get_data.name = payload.name;
          get_data.phone = payload.phone;
          get_data.address = payload.address;
          localStorage.setItem("user", JSON.stringify(get_data));
          context.commit(SET_PERSONAL_INFO, JSON.parse(window.localStorage.getItem('user')));
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
