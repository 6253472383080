import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH, LOGOUT } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import axios from "axios";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import { ToggleButton } from "vue-js-toggle-button";
import "@/view/main.css";

// custom work
import "../public/css/materialdesignicons.min.css";
import "swiper/swiper-bundle.css";
import Vuelidate from "vuelidate";
import { BootstrapVue } from "bootstrap-vue";
var VueScrollTo = require("vue-scrollto");
import Scrollspy from "vue2-scrollspy";
import VueYoutube from "vue-youtube";
import vueVimeoPlayer from "vue-vimeo-player";
import VueMasonry from "vue-masonry-css";
import Skeleton from "vue-loading-skeleton";
import Notifications from "vue-notification";

// API service init
ApiService.init();

// custom work
Vue.use(Notifications);
Vue.use(Skeleton);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease",
});

Vue.use(Scrollspy);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(VueMasonry);

// Remove this to disable mock API
MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  next();
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
Vue.component("ToggleButton", ToggleButton);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
