const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('token');

};

export const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  if(user)
  {
    return JSON.parse(user)
  }
  else
  {
    return null;
  }
  
};


export default { getToken, saveToken, destroyToken, getCurrentUser };
